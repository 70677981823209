import React from 'react';

import { useColorMode } from '@chakra-ui/react';

interface MagicButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const MagicButton = ({ children, onClick }: MagicButtonProps) => {
  const { colorMode } = useColorMode();
  return (
    <button
      onClick={onClick}
      className="relative inline-flex h-12 overflow-hidden rounded-full p-[1px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50"
    >
      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#393BB2_50%,#E2CBFF_100%)]" />

      <span
        className={`inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full ${
          colorMode === 'dark'
            ? 'bg-slate-950 text-white'
            : 'bg-slate-50 text-black'
        } px-3 py-1 text-xs font-medium backdrop-blur-3xl`}
      >
        {children}
      </span>
    </button>
  );
};
